import React, { useEffect, useState } from 'react';

import { HolderAccount } from "../../../../src/interfaces/interfaces";
import { log } from 'console';

// Define PoolData interface
interface PoolData {
    name?: string;
    symbol?: string;
    description?: string;
    image_uri?: string;
    twitter?: string;
    telegram?: string;
    website?: string;
    mint?: string;
    bonding_curve?: string;
    creator?: string;
    market_cap?: number;
    real_sol_reserves?: number;
    real_token_reserves?: number;
    virtual_sol_reserves?: number;
    virtual_token_reserves?: number;
    created_timestamp_ago?: string;
    last_trade_timestamp_ago?: string;
    last_reply_ago?: string;
    buyAmount?: number;
    currentAmount?: number;
    elapsed?: string;
    holders?: HolderAccount[];
}

// Define props type
interface ResumeProps {
    poolData: PoolData | null;
    holdersData: HolderAccount[] | null;
}

// Utility function to convert IPFS URI to a usable URL
const convertIpfsUriToUrl = (ipfsUri: string): string => {
    const ipfsHash = ipfsUri.split('/ipfs/')[1];
    return `https://pump.mypinata.cloud/ipfs/${ipfsHash}`;
};

const tokenDecimals = 10 ** 6;
let initialVirtualTokenReserves = 1073000000

const Resume: React.FC<ResumeProps> = ({ poolData, holdersData }) => {
    const [imageUrl, setImageUrl] = useState<string>('/pf-logo.png'); // Default pf-logo image
    const [isCopied, setIsCopied] = useState(false);

    // Initialize timeElapsed with the initial value from `poolData.created_timestamp_ago` in seconds
    const [timeElapsed, setTimeElapsed] = useState<number>(parseInt((poolData?.created_timestamp_ago) || '0'));
    const [totalPercentage, setTotalPercentage] = useState<string>("0.00");

    useEffect(() => {
        // console.log("Resume re-rendered with poolData:", parseInt(poolData?.created_timestamp_ago));
        
        if (poolData?.image_uri) {
            setImageUrl(convertIpfsUriToUrl(poolData.image_uri));
        }
    }, [poolData]);

    // Helper function to format address to 10 chars with ellipses in between
    const formatAddress = (address: string) => {
        return address.length > 8 ? `${address.slice(0, 4)}...${address.slice(-4)}` : address;
    };

    function formatTimeDifference(seconds: number) {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
    
        if (hrs > 0) {
            return `${hrs}h ${mins}m ${secs}s ago`;
        } else if (mins > 0) {
            return `${mins}m ${secs}s ago`;
        } else {
            return `${secs}s ago`;
        }
    }

    
    useEffect(() => {
        // Parse the initial timestamp and set the elapsed time
        const initialTimestamp = parseInt(poolData?.created_timestamp_ago || '0');
        if (!isNaN(initialTimestamp)) {
            setTimeElapsed(initialTimestamp);
        }

        // Set up an interval to update the time every second
        const intervalId = setInterval(() => {
            setTimeElapsed(prevTime => prevTime + 1);
        }, 1000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [poolData]); // Depend on poolData to re-initialize on changes

    // Get the formatted time ago
    const timeAgo = formatTimeDifference(timeElapsed);

    useEffect(() => {
        // Set up an interval to update the time every second
        const intervalId = setInterval(() => {
            // Calculate the total holding percentage
            setTotalPercentage(holdersData ? holdersData.reduce(
                (acc, holder) => acc + (Number(holder.balance) / initialVirtualTokenReserves) * 100 / 10 ** 6,
                0
            ).toFixed(2) : "0.00");

            // console.log("Resume re-rendered with holdersData:", holdersData);
            // console.log('totalPercentage: ', totalPercentage);
            
        }, 2000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [holdersData, totalPercentage]);

    // Function to handle copying to clipboard
    const copyToClipboard = () => {
        if (poolData && poolData.mint) {
            navigator.clipboard.writeText(poolData.mint);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Reset "Copied!" status after 2 seconds
        }
    };

    if (!poolData) return (
        // <div className="p-6 max-h-[640px]">
        <div className="p-6">
            <div className="space-y-2">
                {/* Default pf-logo background */}
                <div className="absolute inset-0 w-full h-full rounded-lg opacity-50 overflow-hidden">
                    <img
                        src={imageUrl}
                        className="object-cover w-full h-full"
                        onError={() => setImageUrl('/pf-logo.png')}
                    />
                </div>
            </div>
        </div>
    );

    // Helper function to format address to 10 chars with ellipses in between
    const formatDescription = (description: string | any[]) => {
        if (description.length <= 120) return description;
        return `${description.slice(0, 120)}...`;
    };

    // Use holdersData instead of poolData.holders
    const sortedHolders = holdersData ? [...holdersData].sort((a, b) => Number(b.is_creator) - Number(a.is_creator)) : [];

    // Check if creator still owns any tokens
    const creator = holdersData ? holdersData.find(holder => holder.is_creator) : null;
    const creatorStatus = creator
        ? `Creator still owns ${(Number(creator.balance) / initialVirtualTokenReserves * 100 / 10 ** 6).toFixed(2)}%`
        : 'Creator sold';

    return (
        // <div className="p-6 max-h-[640px]">
        <div className="p-0">
            <div className="">
                
                {/* Background */}
                <div className="absolute inset-0 w-full h-full rounded-lg opacity-50 overflow-hidden">
                    <img
                        src={imageUrl}
                        alt={poolData.name || 'Image'}
                        className="object-cover w-full h-full"
                        onError={() => setImageUrl('/pf-logo.png')}
                    />
                </div>

                <div className="grid grid-cols-4">
                    {/* General Info */}
                    <div className="col-span-4 shadow rounded-lg p-2 md:p-4 grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4">
                        <div className="z-10 col-span-2 backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5] shadow rounded-lg p-6 grid grid-cols-4 md:grid-cols-1 border-2 border-[#86EFAC]">
                            {/* Pool Image */}
                            <div className="flex flex-row space-x-2 mb-2">
                                <div className="">
                                    <div className="flex flex-row ">
                                        <img
                                            src={imageUrl}
                                            alt={poolData.name || 'Image'}
                                            className="w-24 h-24 min-w-24 min-h-24 rounded-lg"
                                            onError={() => setImageUrl('/pf-logo.png')} // Set fallback image on error
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row space-x-1">
                                    <div className="grid grid-rows-3 mr-0">
                                        <div className="">
                                            <a href={`https://pump.fun/${poolData.mint}`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">
                                                <img src="/pf-logo.png" alt="Logo" width={64} height={64} className="w-6 h-6" />
                                            </a>
                                        </div>
                                        <div className="">
                                            <a href={`https://birdeye.so/token/${poolData.mint}/${poolData.bonding_curve}?chain=solana`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={64} viewBox="0 0 32 32" fill="none" className="w-4 h-8">
                                                    <path d="M24.9531 13.2437C23.1094 12.1312 22.3094 9.3375 23.1219 7.4375C23.7625 5.94063 26.0688 0.625 26.0688 0.625H18.0687L0.25 36.3031C0.25 36.3031 5.24688 32.9344 12.6219 36.1656C14.9281 37.1781 17.3562 38.1344 20.0906 37.9719C26.5281 37.5875 31.675 32.6187 32.2594 26.1906C32.7781 20.5187 29.5625 16.0219 24.9563 13.2406L24.9531 13.2437ZM27.0719 25.775C26.7094 29.4375 23.7469 32.4 20.0844 32.7594C15.1344 33.25 11.0188 29.1312 11.5031 24.1781C11.8656 20.5156 14.8281 17.5531 18.4906 17.1906C23.4438 16.7031 27.5625 20.8219 27.0719 25.775Z" fill="white"></path>
                                                    <path d="M22.9875 25.3533C22.8156 27.0939 21.4063 28.5033 19.6656 28.672C17.3125 28.9064 15.3563 26.947 15.5875 24.5939C15.7594 22.8533 17.1688 21.4439 18.9094 21.272C21.2625 21.0408 23.2219 22.997 22.9875 25.3533Z" fill="#FF6D00"></path>
                                                </svg>
                                            </a>
                                        </div>
                                        <div className="">
                                            <a href={`https://solscan.io/token/${poolData.mint}/${poolData.bonding_curve}?chain=solana`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">
                                                <img src="/ss-logo.png" alt="Logo" width={64} height={64} className="w-5 h-5" />
                                            </a>
                                        </div>
                                    </div>
                                    {(poolData.twitter || poolData.telegram || poolData.website) && (
                                        <div className="grid grid-rows-3">
                                            {poolData.twitter && (
                                            <div className="flex flex-row items-center space-x-1">
                                                <a href={`${poolData.twitter}`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">                                                
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="white" viewBox="0 0 12 12"><g clip-path="url(#clip0_7920_513)"><path d="M9.282 1h1.71L7.255 5.27l4.394 5.809H8.21L5.515 7.555 2.43 11.08H.721l3.995-4.567L.5 1h3.528l2.436 3.22L9.282 1zm-.6 9.056h.947L3.513 1.97H2.497l6.185 8.086z"></path></g><defs><clipPath id="clip0_7920_513"><rect width="12" height="12"></rect></clipPath></defs></svg>
                                                </a>
                                                <a href={`${poolData.twitter}`} className="text-[#86EFAC] underline text-sm">{`${poolData.twitter.slice(0,36)}`}</a>
                                            </div>
                                            )}
                                            {poolData.telegram && (
                                            <div className="flex flex-row items-center space-x-1">
                                                <a href={`${poolData.telegram}`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">     
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="white" viewBox="0 0 12 12"><g clip-path="url(#clip0_7920_515)"><path d="M11.894 1.91l-1.8 8.487c-.134.6-.49.746-.992.465L6.36 8.842l-1.322 1.273c-.147.147-.27.27-.551.27l.196-2.793L9.764 3c.22-.196-.05-.307-.344-.11L3.138 6.844.43 6c-.588-.183-.6-.588.122-.869l10.582-4.078c.49-.183.918.11.76.857z"></path></g><defs><clipPath id="clip0_7920_515"><rect width="12" height="12"></rect></clipPath></defs></svg>
                                                </a>
                                                <a href={`${poolData.telegram}`} className="text-[#86EFAC] underline text-sm">{`${poolData.telegram.slice(0,36)}`}</a>
                                            </div>
                                            )}
                                            {poolData.website && (
                                            <div className="flex flex-row items-center space-x-1">
                                                <a href={`https://${poolData.website}`} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-8 h-8">  
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="white" viewBox="0 0 20 20"><g clip-path="url(#clip0_1553_2200)"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM6.446 2.831A8.037 8.037 0 003.07 6h2.323c.212-1.023.505-1.96.865-2.77.06-.136.123-.269.188-.399zM2 10c0-.69.088-1.36.252-2h2.842a21.008 21.008 0 000 4H2.252A8.013 8.013 0 012 10zm1.07 4a8.037 8.037 0 003.376 3.169 9.877 9.877 0 01-.188-.399c-.36-.81-.653-1.747-.865-2.77H3.07zm4.372 0c.173.732.392 1.392.643 1.958.328.738.693 1.273 1.047 1.61.35.333.641.432.868.432.227 0 .518-.1.867-.432.355-.337.72-.872 1.048-1.61.251-.566.47-1.226.643-1.958H7.442zm7.165 0a13.716 13.716 0 01-.865 2.77c-.06.136-.123.269-.188.399A8.037 8.037 0 0016.93 14h-2.323zm3.14-2h-2.841a21.027 21.027 0 000-4h2.842c.165.64.252 1.31.252 2s-.087 1.36-.252 2zm-4.851 0H7.104A18.907 18.907 0 017 10c0-.693.037-1.362.104-2h5.792c.067.638.104 1.307.104 2 0 .693-.037 1.362-.104 2zm1.71-6h2.324a8.037 8.037 0 00-3.376-3.169c.065.13.128.263.188.399.36.81.653 1.747.865 2.77zm-6.52-1.958c-.252.566-.47 1.226-.644 1.958h5.116a11.248 11.248 0 00-.643-1.958c-.328-.738-.693-1.273-1.047-1.61C10.518 2.099 10.226 2 10 2c-.227 0-.518.1-.868.432-.354.337-.719.872-1.047 1.61z"></path></g><defs><clipPath id="clip0_1553_2200"><rect width="20" height="20"></rect></clipPath></defs></svg>
                                                </a>
                                                <a href={`${poolData.website}`} className="text-[#86EFAC] underline text-sm font-medium font-sans">{`${poolData.website.slice(0,36)}`}</a>
                                            </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            
                            {/* Mint Address with Tooltip and Clipboard Copy */}
                            <div className="col-span-full text-sm mt-2 relative">
                                <p
                                    className="cursor-pointer"
                                    onClick={copyToClipboard}
                                    title={isCopied ? 'Copied!' : 'Click to copy'}
                                >
                                    {formatAddress(poolData.mint || 'N/A')}
                                </p>
                                {/* Tooltip */}
                                <div
                                    className={`absolute w-max bg-gray-700 text-white text-xs rounded p-1 transition-opacity duration-300 ${
                                        isCopied ? 'opacity-1' : 'opacity-0'
                                    }`}
                                    style={{
                                        bottom: '100%',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                    }}
                                >
                                    {isCopied ? 'Copied!' : poolData.mint}
                                </div>
                            </div>

                            <p className="mt-1 font-semibold">{poolData.name || 'N/A'} ({poolData.symbol || 'N/A'})</p>
                            <p className="text-sm text-white">{poolData.description ? formatDescription(poolData.description) : 'N/A'}</p>
                            <div className="pt-2">
                            </div>
                        </div>

                        {/* Rug Stats */}
                        {/* {(poolData.twitter || poolData.telegram || poolData.website) && (
                            <div className="z-10 backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5] shadow rounded-lg p-6 flex flex-col space-y-4 border-2 border-[#86EFAC] justify-center">
                                {poolData.twitter && (
                                    <p className="w-full text-center flex flex-col">
                                        <strong className="text-medium">Twitter:</strong>
                                        <a href={poolData.twitter} className="text-[#86EFAC] underline text-sm">{poolData.twitter}</a>
                                    </p>
                                )}
                                {poolData.telegram && (
                                    <p className="w-full text-center flex flex-col">
                                        <strong className="text-medium">Telegram:</strong>
                                        <a href={poolData.telegram} className="text-[#86EFAC] underline text-sm">{poolData.telegram}</a>
                                    </p>
                                )}
                                {poolData.website && (
                                    <p className="w-full text-center flex flex-col">
                                        <strong className="text-medium">Website:</strong>
                                        <a href={poolData.website} className="text-[#86EFAC] underline text-sm">{poolData.website}</a>
                                    </p>
                                )}
                            </div>
                        )} */}

                        {/* Activity Stats */}
                        {/* <div className={`col-span-1 z-10 backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5] shadow rounded-lg p-6 flex flex-col gap-2 border-2 border-[#86EFAC] justify-center`}>
                            <div><p><strong>Last Trade:</strong> {poolData.last_trade_timestamp_ago || 'N/A'} ago</p></div>
                            <div><p><strong>Last Reply:</strong> {poolData.last_reply_ago || 'N/A'} ago</p></div>
                        </div> */}

                        {/* Bars */}
                        <div className={`col-span-1 md:col-span-2 lg:col-span-2 z-10 backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5] shadow rounded-lg p-6 flex flex-col gap-2 border-2 border-[#86EFAC] justify-start`}>
                            <p className="text-lg font-semibold text-nowrap">
                                <strong className="text-md font-semibold text-nowrap">Created: </strong>
                                {timeAgo}
                            </p>
                            {/* <p className="text-lg font-semibold text-nowrap">{creatorStatus}</p>
                            <p className="text-lg font-semibold text-nowrap">
                                <strong className="text-md font-semibold text-nowrap">{holdersData?.length} Holders currently owns: </strong>
                                {totalPercentage}%
                            </p> */}
                        </div>

                        {/* New section for showing holders */}
                            {/* <div className="z-10 col-span-full backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5] shadow rounded-lg p-6 grid grid-cols-1 md:grid-cols-3 gap-2 border-2 border-[#86EFAC]">
                                <div className="holders-section w-full">
                                    <ul>
                                        <div className="">
                                            <p className="text-xl font-semibold text-nowrap">{creatorStatus}</p>
                                            <p className="text-xl font-semibold text-nowrap">
                                                <strong className="text-xl font-semibold text-nowrap">{holdersData?.length} Holders currently owns: </strong>
                                                {totalPercentage}%
                                            </p>
                                            <p className="text-xl font-semibold text-nowrap">
                                                <strong className="text-xl font-semibold text-nowrap">Created: </strong>
                                                {timeAgo}
                                            </p>
                                        </div> */}
                                        {/* <table className="rounded-lg overflow-hidden">
                                            <thead>
                                                <tr className="uppercase text-sm leading-normal">
                                                    <th className="py-3 px-6 text-left">Account</th>
                                                    <th className="py-3 px-6 text-right">Amount</th>
                                                    <th className="py-3 px-6 text-right">Holding %</th>
                                                    <th className="py-3 px-6 text-right"></th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-sm font-light">
                                                {holdersData.map((holder, index) => (
                                                    <tr
                                                        key={index}
                                                        className={`border-b border-gray-200 hover:bg-gray-100 ${
                                                            holder.is_creator ? 'bg-gray-800' : ''
                                                        }`} // Apply background color if creator
                                                    >
                                                        <td className="py-3 px-6 text-left truncate">{holder.account.toBase58().slice(0,10)}...</td>
                                                        <td className="py-3 px-6 text-right">{(holder.balance / tokenDecimals).toFixed(4)}</td>
                                                        <td className="py-3 px-6 text-right">
                                                            {((holder.balance / initialVirtualTokenReserves) * 100 / 10**6).toFixed(2)}%
                                                        </td>
                                                        <td className="py-3 px-6 text-left truncate">{holder.is_creator}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> */}
                                    {/* </ul>
                                </div>
                            </div> */}
                    </div>
                            
                    {/* <Chart /> */}
                    { poolData && (
                        <div className="col-span-4 z-10 p-2 md:p-4 py-0 md:py-0 pb-2 md:pb-4">
                            <div className="z-10 col-span-2 chart-container backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5] shadow border-2 border-[#86EFAC] h-full rounded-lg">
                                <iframe
                                width="100%"
                                height="420"
                                src={`https://birdeye.so/tv-widget/${poolData.mint}/${poolData.bonding_curve}?chain=solana&viewMode=pair&chartInterval=1&chartType=CANDLE&chartTimezone=Asia%2FSingapore&chartLeftToolbar=hide&theme=dark`}
                                frameBorder="0"
                                allowFullScreen
                                ></iframe>     
                            </div>                
                        </div>                       
                    )}
                </div>
            </div>
        </div>
    );
};

export default Resume;
